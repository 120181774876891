import React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

export default function (props)
{
  const [isShowing, setIsShowing] = useState(false)


  return (
     
    <div className="flex justify-center">
     
        <button onClick={() => setIsShowing((isShowing) => !isShowing)}>
        Toggle
      </button>
      


    <Transition show={isShowing}>
      {/* Background overlay */}
      <Transition.Child
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        coucou transition 1
      </Transition.Child>

      {/* Sliding sidebar */}
      <Transition.Child
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        coucou transition 2
      </Transition.Child>
    </Transition>



     </div>
     
    );

}