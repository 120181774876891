import React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';




export default function (props)
{
console.log('items');
 /* {Object.keys(props['menuUser']).map((item) => (
   console.log(item)
  ))}*/

  var i=1;
  {[props['menuUser']].map((item) => (
    
    console.log(props.menuUser.nbElementsMenu)+console.log(props.menuUser['item'+i])+console.log(props.menuUser['item'+i]['linkUrl'])

   ))}



   {(() => {
    for (let i = 1; i <= props.menuUser.nbElementsMenu; i++) {
      let item = props.menuUser['item'+i];
      console.log('result i:'+i);
    }

  })()}



 

     //let menuUser = generateMenuUser(props);

     return (
    <div class="flex items-center">
      <Menu as="div" className="relative inline-block text-left z-30">
        <div class="flex items-center">
          <Menu.Button >
             <div id="block-user-icon">
             <img src={props.avatarUrlThumbnails} class="rounded-md" alt="utilisateur" />
             </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute -right-0 w-72 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
           


          <Menu.Item>
                <div className='flex px-2 py-2 bg-neutral-700 text-white'>
                  <div className="w-1/4"><img src={props.avatarUrl} class="rounded-md" alt="utilisateur" /></div>
                  <div className="w-3/4 pl-2">
                    <div>{props.fullName}</div>
                    <div className="text-sm">{props.email}</div>
                  </div>
                </div>
          </Menu.Item>


            {(() => {
              for (let i = 1; i <= props.menuUser.nbElementsMenu; i++) {

                return(<div class="px-1 py-1 ">
                  <Menu.Item>
                  
                  {({ active }) => 
                  (
                      <a href={props.menuUser['item'+i].linkUrl} target={props.menuUser['item'+i].linkTarget} className={`block text-center px-2 py-2 text-sm dropdown-item user-action ${props.menuUser['item'+i].cssClass}`} rel={ props.menuUser['item'+i].linkRel } referrerpolicy="origin-when-cross-origin">
                          {props.menuUser['item'+i].icon != ''
                            ? (<i class={`fa fa-fw ${ props.menuUser['item'+i].icon }`}></i>)
                            :('')
                          } 
                        <span>{ props.menuUser['item'+i].label }</span>
                      </a>
                  
                  )}

                  </Menu.Item>
                </div>)
              }

            })()}


    
           
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}



function generateMenuUser(props)
{

  let menuUser ='';
  let elementMenuUser  = Object.keys(props['menuUser']).map(function(key)
  {
    let item = props['menuUser'][key];

    return(
      <div class="px-1 py-1 ">
        <Menu.Item>
        
          {({ active }) => 
          (
              <a href={item.linkUrl} target={item.linkTarget } className={`dropdown-item user-action ${item.cssClass}`} rel={item.linkRel} referrerpolicy="origin-when-cross-origin">
                if(${item.icon != ''})
                {
                    <i class={"fa fa-fw ${ item.icon }"}></i>
                }
                <span>{ item.label }</span>
              
              </a>
          
          )}

        </Menu.Item>
      </div>
    );

      item = undefined;

  });

    console.log('elementMenuUser');
    console.log(elementMenuUser);
  menuUser = menuUser + elementMenuUser;
  



  return (menuUser);
 

    //console.log('menuUser');
    //console.log(menuUser);


}

function EditInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
    </svg>
  )
}

function EditActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
    </svg>
  )
}

function DuplicateInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H12V12H4V4Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path
        d="M8 8H16V16H8V8Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
    </svg>
  )
}

function DuplicateActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H12V12H4V4Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <path
        d="M8 8H16V16H8V8Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
    </svg>
  )
}

function ArchiveInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="8"
        width="10"
        height="8"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <rect
        x="4"
        y="4"
        width="12"
        height="4"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  )
}

function ArchiveActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="8"
        width="10"
        height="8"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <rect
        x="4"
        y="4"
        width="12"
        height="4"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  )
}

function MoveInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 4H16V10" stroke="#A78BFA" strokeWidth="2" />
      <path d="M16 4L8 12" stroke="#A78BFA" strokeWidth="2" />
      <path d="M8 6H4V16H14V12" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  )
}

function MoveActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 4H16V10" stroke="#C4B5FD" strokeWidth="2" />
      <path d="M16 4L8 12" stroke="#C4B5FD" strokeWidth="2" />
      <path d="M8 6H4V16H14V12" stroke="#C4B5FD" strokeWidth="2" />
    </svg>
  )
}

function DeleteInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="6"
        width="10"
        height="10"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path d="M3 6H17" stroke="#A78BFA" strokeWidth="2" />
      <path d="M8 6V4H12V6" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  )
}

function DeleteActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="6"
        width="10"
        height="10"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <path d="M3 6H17" stroke="#C4B5FD" strokeWidth="2" />
      <path d="M8 6V4H12V6" stroke="#C4B5FD" strokeWidth="2" />
    </svg>
  )
}

