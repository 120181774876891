import React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

let firstLoad = true;

export default function(props) {

  const targetRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const showSearchInput = isHovered || isFocused;

  const [valeurSearch, setValeurSearch] = useState(props.value);

 
  const changeValeurSearch = event => {
    setValeurSearch(event.target.value);
  };

  /* lors de la perte du focus on verifie si le champs de recherche contient une valeur, si c'est le cas on laisse le input affiché sinon ca redevient une icone */
  const blurHandler = () => {
    if(document.getElementById("searchbar").value == '')
    {
      /*ca permet de changer letat de showSearchInput */
      setIsFocused(false);
    }
    else
    {
      /*ca permet de changer letat de showSearchInput */
      setIsFocused(true);
    }
  }


  const focusHandler = () => {
    if(firstLoad)
    {
      firstLoad =false;
      //setIsFocused(false);
      focusOutSearchBar() ;
      document.getElementById("searchbar").blur();
    }
    else
    {
      setIsFocused(true);
    }
    
  }

  useEffect(() => {
    /* code  apres render*/
    console.log('onload');
    console.log(firstLoad);
    if(firstLoad)
    {
      firstLoad =false;
      if(props.value != '')
      {
        console.log('getfocus');
        setIsFocused(true);
        document.getElementById("searchbar").focus();
      }
      else
      {
        document.getElementById("searchbar").blur();
      }
    }
    /* on enlève le focus sur le champs recherche lors du chargement */
   // document.getElementById("searchbar").blur();
    /* fin code  apres render*/
  });

  useEffect(() => {
    targetRef.current.value = props.value;
  }, [showSearchInput]);

    /* code  avant render*/

    /* fin code  apres render*/
   

  return (
     
        <div className="flex justify-center w-60">
            <div id="searchContainer" className="searchContainer slideBorderEffect"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onFocus={() => setIsFocused(true)}
              
              //onBlur={() => setIsFocused(true)}
              onBlur={() => blurHandler()}
              hover={showSearchInput}
            >
              
              <input id="searchbar" onChange={changeValeurSearch} onFocus={focusSearchBar} onBlur={focusOutSearchBar} /*genere des erreurs onInput={props.onInput}*/ className={`searchInput form-control left-[3px] top-[3px] ${props.class}`}  type="search" name="query" spellcheck="false" autocorrect="off"  ref={targetRef} showSearchInput={showSearchInput} />
              {showSearchInput ? 
              <div className="absolute right-[3px] top-[3px]">
                {/*<a class="scroll-up"><span class="left-bar"></span><span class="right-bar"></span> <svg width="40" height="40"><line class="top" x1="0" y1="0" x2="120" y2="0"/><line class="left" x1="0" y1="40" x2="0" y2="-80"/><line class="bottom" x1="40" y1="40" x2="-80" y2="40"/> <line class="right" x1="40" y1="0" x2="40" y2="1200"/></svg></a>*/}
                <a class="arrow-up"><span class="left-arm"></span><span class="center-arm"></span><span class="right-arm"></span><span class="arrow-slide"></span></a>
              </div>


              : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="iconSearch iconCommonCss w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" /></svg>}
            </div>
         </div>
         
        );

        
            
}

function submitSearch()
{
  
  document.getElementById("form-search").submit();
}

function focusSearchBar() 
{
  document.getElementById("searchContainer").classList.add('focusSearchBar');
  document.getElementById("searchContainer").classList.add('reelFocusSearchBar');
}
function focusOutSearchBar() 
{
  
  if(document.getElementById("searchbar").value == '')
  {
    
    document.getElementById("searchContainer").classList.remove('focusSearchBar');
  
  }

  document.getElementById("searchContainer").classList.remove('reelFocusSearchBar');
}


